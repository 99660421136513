<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn small v-on="on" @click="dialog = true" class="success">
          <v-icon> mdi-file-plus </v-icon>
        </v-btn>
      </template>
      <span>Chemkin File Scraper</span>
    </v-tooltip>

    <v-dialog
      v-model="dialog"
      fullscreen
      persistent
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar flat class="titlebar" max-height="64">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon @click="close" v-on="on">
                <v-icon> mdi-close </v-icon>
              </v-btn>
            </template>
            <span> Cancel </span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon @click="reset" v-on="on">
                <v-icon> mdi-backup-restore </v-icon>
              </v-btn>
            </template>
            <span> Reset </span>
          </v-tooltip>
          <v-toolbar-title> Scrape Chemkin File </v-toolbar-title>
          <v-spacer></v-spacer>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                class="info"
                @click="scrapeChemkinFile"
                v-show="materials.length === 0"
                v-on="on"
              >
                <v-icon> mdi-file-find </v-icon>
              </v-btn>
            </template>
            <span> Search </span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                class="success"
                @click="saveMaterials"
                v-show="materials.length > 0"
                :disabled="checkedMaterials.length === 0"
                v-on="on"
              >
                <v-icon> mdi-send </v-icon>
              </v-btn>
            </template>
            <span> {{ saveButtonText }} </span>
          </v-tooltip>
        </v-toolbar>
        <v-card v-show="materials.length === 0">
          <v-card-text>
            <v-form class="px-3" ref="form" :disabled="!loading">
              <v-container fluid>
                <v-row style="mx-4">
                  <v-col cols="12">
                    <v-file-input
                      show-size
                      label="Select Chemkin thermo file"
                      v-model="chosenFile"
                      :rules="requiredRule"
                    />
                    <v-switch
                      label="Extended Search"
                      v-model="extendedSearch"
                    ></v-switch>
                  </v-col>
                  <v-col> </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
        </v-card>
        <v-card v-show="materials.length > 0">
          <MaterialIterator
            :materials="materials"
            :species="species"
            :checked="checked"
            @deleteMaterial="deleteMaterial"
            @updateMaterial="updateMaterial"
            @changeChecked="changeChecked"
            @checkAllMaterials="checkAllMaterials"
          />
        </v-card>

        <v-overlay
          :value="loading"
          :absolute="true"
          :opacity="0.9"
          :z-index="5"
        >
          <v-progress-circular
            indeterminate
            color="info"
          ></v-progress-circular>
        </v-overlay>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import MaterialIterator from './MaterialIterator';
export default {
  name: 'ChemkinPopup',
  components: { MaterialIterator },
  data() {
    return {
      loading: false,
      dialog: false,
      requiredRule: [v => (v && !!v) || 'File is required'],
      chosenFile: null,
      extendedSearch: false,
      materials: [],
      species: [], // dummy list (needed for MaterialIterator)
      checked: [],
    };
  },
  methods: {
    ...mapActions('materials', ['addMaterial']),
    scrapeChemkinFile() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        const obj = { extendedSearch: this.extendedSearch };
        const json = JSON.stringify(obj);
        const blob = new Blob([json], {
          type: 'application/json',
        });
        let formData = new FormData();
        formData.append('file', this.chosenFile);
        formData.append('data', blob);
        this.$store
          .dispatch('materials/scrapeChemkinFile', formData)
          .then(response => {
            this.materials = response.data.materials;
            this.loading = false;
            var snack = {
              text: response.data.msg,
              color: 'success',
            };
            this.$store.dispatch('misc/setSnack', snack);
          })
          .catch(error => {
            console.log(error);
            var snack = {
              text: error.response.data.msg,
              color: 'error',
            };
            this.$store.dispatch('misc/setSnack', snack);
            this.loading = false;
          });
      }
    },
    deleteMaterial(publicId) {
      this.materials = this.materials.filter(function(mat) {
        return mat.publicId !== publicId;
      });
    },
    updateMaterial(updatedMaterial) {
      Object.assign(
        this.materials[
          this.materials.findIndex(
            mat => mat.publicId === updatedMaterial.publicId,
          )
        ],
        updatedMaterial,
      );
    },
    saveMaterials() {
      var checkedMaterials = this.checkedMaterials;
      // delete checked key
      checkedMaterials.forEach(material => delete material.checked);
      this.addMaterial(checkedMaterials)
        .then(response => {
          var snack = {
            text: response.data.msg,
            color: 'success',
          };
          this.$store.dispatch('misc/setSnack', snack);
        })
        .catch(error => {
          var snack = {
            text: error.response.data.msg,
            color: 'success',
          };
          this.$store.dispatch('misc/setSnack', snack);
          console.log(error);
        });
      this.close();
    },
    checkAllMaterials() {
      var zeroChecked = this.checked.length === 0;
      this.checked = []; // empty checked array in any case
      if (zeroChecked) {
        // check all species except for species that are already in the mixture
        this.materials.forEach(mat => {
          if (!this.species.some(s => s.publicId === mat.publicId)) {
            this.checked.push(mat.publicId);
          }
        });
      }
    },
    changeChecked(publicId) {
      const index = this.checked.findIndex(el => el === publicId);
      if (index === -1) {
        this.checked.push(publicId);
      } else {
        this.checked = this.checked.filter(e => e != publicId);
      }
    },
    close() {
      this.dialog = false;
    },
    reset() {
      this.$refs.form.reset();
      this.materials = [];
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  computed: {
    saveButtonText() {
      return this.materials.length > 1
        ? `Save Selected Materials (${this.checkedMaterials.length})`
        : 'Save Selected Material';
    },
    checkedMaterials() {
      var checkedMaterials = [];
      this.checked.forEach(publicId => {
        var material = this.materials.find(
          mix => mix.publicId === publicId,
        );
        checkedMaterials.push(material);
      });
      return checkedMaterials;
    },
  },
};
</script>

<style></style>
