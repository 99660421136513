<template>
  <div>
    <v-container fluid class="my-5">
      <MaterialTable> </MaterialTable>
    </v-container>
  </div>
</template>

<script>
import MaterialTable from '@/components/materials/MaterialTable';

export default {
  name: 'MaterialDatabase',
  components: { MaterialTable },
};
</script>

<style></style>
