<template>
  <v-data-table
    :loading="loading"
    :headers="headers"
    :items="materials"
    sort-by="name"
    class="elevation-1"
    :search="search"
    :footer-props="{
      itemsPerPageOptions: [5, 10, 20], // -1: All
      showFirstLastPage: true,
      firstIcon: 'mdi-chevron-double-left',
      lastIcon: 'mdi-chevron-double-right',
      prevIcon: 'mdi-chevron-left',
      nextIcon: 'mdi-chevron-right',
    }"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small class="info mx-1" v-on="on" @click="refresh">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>Refresh</span>
        </v-tooltip>
        <MaterialPopup
          class="mx-1"
          v-if="admin"
          :inputMaterial="emptyMaterial()"
          :show="true"
          :readonly="false"
          iconColor="white"
          buttonClass="success"
        />
        <ChemkinPopup class="mx-1" v-if="admin" />
        <NistPopup class="mx-1" v-if="admin" />
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              small
              class="info mx-1"
              v-on="on"
              @click="exportMaterials"
              v-if="admin"
            >
              <v-icon>mdi-file</v-icon>
            </v-btn>
          </template>
          <span>Export materials to File</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              small
              class="error mx-1"
              v-on="on"
              @click="deleteAllMaterials"
              v-if="admin"
            >
              <v-icon>mdi-nuke</v-icon>
            </v-btn>
          </template>
          <span>Delete All Materials</span>
        </v-tooltip>
      </v-toolbar>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-row>
        <MaterialPopup
          :inputMaterial="item"
          :show="true"
          :readonly="true"
          iconColor="white"
          buttonClass="info"
        />
        <MaterialPopup
          v-if="admin"
          :inputMaterial="item"
          :show="true"
          :readonly="false"
          iconColor="white"
          buttonClass="warning"
        />
        <v-tooltip bottom v-if="admin">
          <template v-slot:activator="{ on }">
            <v-btn
              small
              class="error"
              v-on="on"
              @click="deleteMaterial(item.publicId)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>Delete</span>
        </v-tooltip>
        <!-- <MaterialExportButton :inputMaterial="item" :show="true" /> -->
      </v-row>
    </template>
    <template v-slot:[`item.formula`]="{ item }">
      <span v-html="htmlFormula(item.formula)"> </span>
    </template>
    <template v-slot:[`item.lastModified`]="{ item }">
      <span>{{ item.lastModified | formatDate }}</span>
    </template>
    <template v-slot:[`item.molarMass`]="{ item }">
      {{ round(item.molarMass * 1000, 3) }}
    </template>
    <template v-slot:[`item.hf0`]="{ item }">
      {{ round(item.hf0 / 1000, 2) }}
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import _ from 'lodash';
import MaterialPopup from './MaterialPopup';
// import MaterialExportButton from './MaterialExportButton';
import ChemkinPopup from './ChemkinPopup';
import NistPopup from './NistPopup';

export default {
  name: 'MaterialTable',
  components: {
    MaterialPopup,
    ChemkinPopup,
    NistPopup,
    // MaterialExportButton,
  },
  data() {
    return {
      headers: [
        { text: 'Name', align: 'start', value: 'name' },
        { text: 'Formula', value: 'formula' },
        { text: 'CAS', value: 'cas' },
        { text: 'Phase', value: 'phase' },
        { text: 'Molar Mass [kg/kmol]', value: 'molarMass' },
        { text: 'Enthalpy of Formation [kJ/mol]', value: 'hf0' },
        { text: 'Last Modified', value: 'lastModified' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      loading: false,
      search: '',
    };
  },
  methods: {
    ...mapActions('materials', ['getMaterials']),
    deleteMaterial(publicId) {
      this.$store
        .dispatch('materials/deleteMaterial', publicId)
        .then(response => {
          console.log('Material successfully deleted from database!');
        })
        .catch(error => {
          console.log('Error deleting material from database...');
          console.log(error);
        });
    },
    deleteAllMaterials() {
      this.$store
        .dispatch('materials/deleteAllMaterials')
        .then(response => {
          console.log(
            'All Materials successfully deleted from database!',
          );
        })
        .catch(error => {
          console.log(
            'Error deleting all materials from database...',
          );
          console.log(error);
        });
    },
    refresh() {
      this.loading = true;
      this.getMaterials()
        .then(response => {
          var snack = {
            text: 'Materials successfully fetched from Database!',
            color: 'success',
          };
          this.$store.dispatch('misc/setSnack', snack);
          this.loading = false;
        })
        .catch(error => {
          var snack = {
            text: 'Error fetching Materials from Database!',
            color: 'error',
          };
          this.$store.dispatch('misc/setSnack', snack);
          this.loading = false;
        });
    },
    exportMaterials() {
      this.$store.dispatch('misc/saveObjectToFile', {
        obj: this.materials,
        filename: 'materials',
      });
    },
    round(value, precision) {
      return _.round(value, precision);
    },
  },
  computed: {
    ...mapGetters('materials', [
      'materials',
      'emptyMaterial',
      'htmlFormula',
    ]),
    ...mapGetters(['admin']),
  },
  mounted() {
    // this.getMaterials().then(response => {
    //   this.loading = false;
    // });
  },
};
</script>

<style></style>
